@import './colors.scss';
@import './responsive.scss';
@import './vars.scss';

.headline_medium {
  font-size: 26px;
  font-weight: 600;
  line-height: 32px;

  @include only-on(lgUp) {
    font-size: 42px;
    line-height: 50px;
  }
}

.headline_small {
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;

  @include only-on(lgUp) {
    font-size: 22px;
    line-height: 24px;
  }
}

.headline_inner_xl {
  font-weight: 600;
  font-size: 35px;
  line-height: 22px;
}

.headline_inner {
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;

  @include only-on(lgUp) {
    font-size: 26px;
    line-height: 32px;
  }
}

.headline_sub_inner {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;

  @include only-on(lgUp) {
    font-size: 24px;
    line-height: 32px;
  }
}

.text_medium {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  @include only-on(lgUp) {
    font-size: 20px;
    line-height: 28px;
  }
}

.text_small {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  @include only-on(lgUp) {
    font-size: 16px;
    line-height: 20px;
  }
}

.text_xsmall {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;

  @include only-on(lgUp) {
    font-size: 16px;
    line-height: 24px;
  }
}

.headerTextLarge {
  font-family: var(--omnes-font);
  font-size: 22px;
  font-weight: 600;
  color: $vroom-orange;

  @include only-on(lgUp) {
    font-size: 42px;
  }
  @include only-on(xl) {
    font-size: 62px;
  }
}

.headerTextSmall {
  font-family: var(--omnes-font);
  font-size: 17px;
  font-weight: 600;
  color: $vroom-orange;
  @include only-on(lgUp) {
    font-size: 28px;
  }
  @include only-on(xl) {
    font-size: 42px;
  }
}

.titleText {
  font-family: var(--omnes-font);
  color: $vroom-dark-brown;
  font-weight: 500;
  font-size: 16px;

  @include only-on(lgUp) {
    font-size: 18px;
  }
  @include only-on(xl) {
    font-weight: 500;
    font-size: 26px;
  }
}

.bodyText {
  font-family: var(--omnes-font);
  font-size: 14px;
  line-height: 1.2em;
  font-weight: normal;
  color: $vroom-dark-brown;

  @include only-on(lgUp) {
    font-size: 16px;
  }
  @include only-on(xl) {
    font-size: 20px;
  }
}

.linkText {
  @extend .bodyText;
  font-weight: 600;
  color: $vroom-teal;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &.active {
    font-weight: 600;
    color: $vroom-teal-hover;
  }
}

.numberText {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: var(--omnes-font);
  font-weight: 500;
  flex-direction: column;
  font-size: 14px;
  border: solid 1px $vroom-dark-brown;
  border-radius: 25px;
  width: 25px;
  height: 25px;
  margin-bottom: 15px;

  @include only-on(lgUp) {
    font-size: 26px;
    border-radius: 40px;
    width: 40px;
    height: 40px;
    margin-bottom: 20px;
    border: solid 2px $vroom-dark-brown;
  }
}

.quoteText {
  color: $vroom-teal;
  font-size: 22px;
  font-weight: 600;

  @include only-on(lgUp) {
    font-size: 32px;
  }
  @include only-on(xl) {
    font-size: 42px;
  }
}

.quoteAttributionText {
  color: $vroom-teal;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 600;

  @include only-on(lgUp) {
    font-size: 22px;
  }
  @include only-on(xl) {
    margin-top: 30px;
    font-size: 26px;
  }
}

.quoteAttributionTextSmall {
  color: $vroom-teal;
  margin-top: 10px;
  font-size: 14px;
  max-width: $block-text-width-max;
  margin-left: auto;
  margin-right: auto;

  @include only-on(lgUp) {
    font-size: 20px;
  }
  @include only-on(xl) {
    font-size: 26px;
  }
}

.testimonialTitleText {
  color: $vroom-teal;
  font-size: 20px;
  font-weight: 500;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;

  @include only-on(lgUp) {
    font-size: 24px;
    line-height: 28px;
  }
  @include only-on(xl) {
    font-size: 26px;
    line-height: 30px;
  }
}

.testimonialQuoteText {
  font-family: var(--omnes-font);
  font-size: 14px;
  line-height: 1.2em;
  font-weight: normal;
  color: $vroom-dark-brown;

  @include only-on(lgUp) {
    font-size: 16px;
  }
  @include only-on(xl) {
    font-size: 20px;
  }
}
