@import './colors';
@import './vars';
@import '../styles/responsive';

@mixin cta($minWidth: 195px, $height: 54px) {
  display: inline-block;
  color: white;
  background-color: $vroom-teal;
  border-radius: $height;
  min-width: $minWidth;
  line-height: $height;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
  &:hover {
    background-color: $vroom-teal-hover;
    color: white;
    text-decoration: none;
  }

  @include only-on(lgUp) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include only-on(sm) {
    min-width: $minWidth * 0.65;
    line-height: $height * 0.65;
    font-size: 14px;
  }

  @include only-on(md) {
    min-width: $minWidth * 0.75;
    line-height: $height * 0.75;
    font-size: 14px;
  }
}

@mixin container {
  @include only-on(lgUp) {
    margin-left: 20px;
    margin-right: 20px;
  }

  @include only-on(xl) {
    margin-left: auto;
    margin-right: auto;
    max-width: $break-xl;
  }
}

@mixin shadow {
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
}

@mixin plxForeground {
  background-color: rgba(255, 255, 255, 1);
  position: relative;
  margin-top: $navHeight + $bgHeaderHeight;
  z-index: 1000;
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 1px calc($block-spacing / 2);
  @include only-on(lgUp) {
    margin-top: $navHeight-lg + $bgHeaderHeight-lg;
  }
  @include only-on(md) {
    max-width: 700px;
  }
  @include only-on(sm) {
    max-width: 400px;
    padding: 1px 20px 0px 20px;
  }
}
