@import '../../styles/mixins';
@import '../../styles/typography';
@import '../../styles/responsive';

.homepage__foreground {
  @include plxForeground;
  @include container;
  margin-top: 50px;
  @include only-on(lgUp) {
    margin-top: 100px;
    max-width: 960px;
  }
  @include only-on(mdDown) {
    padding-top: 1px;
  }
}
