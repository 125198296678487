@import './vars.scss';

@mixin only-on($media) {
  @if $media == xs {
    @media screen and (max-width: $break-xs - 1) { @content; }
  }
  @else if $media == sm {
    @media screen and (max-width: $break-sm) { @content; }
  }
  @else if $media == mdDown {
    @media screen and (max-width: $break-lg - 1) { @content; }
  }
  @else if $media == md {
    @media screen and (min-width: $break-sm + 1) and (max-width: $break-lg - 1) { @content; }
  }
  @else if $media == mdUp {
    @media screen and (min-width: $break-sm + 1) { @content; }
  }
  @else if $media == lg {
    @media screen and (min-width: $break-lg) and (max-width: $break-xl - 1) { @content; }
  }
  @else if $media == lgUp {
    @media screen and (min-width: $break-lg) { @content; }
  }
  @else if $media == xl {
    @media screen and (min-width: $break-xl) { @content; }
  }

}
