$vroom-orange: #fa9600;
$vroom-light-blue: #d7f5f5;
$vroom-teal: #00a6a2;
$vroom-teal-hover: #00878c;
$vroom-tan: #faf5e6;
$vroom-dark-brown: #47341f;
$vroom-red: #f4443f;

/* Rectangle 7: */
$orange-gradient: linear-gradient(0deg, #ffffff 0%, #fa9600 62%);
/* BG: */
$blue-gradient: linear-gradient(-1deg, rgba(215, 245, 245, 0) 0%, #d7f5f5 100%);
/*transform: rotate(-180deg);*/
$tan-gradient: linear-gradient(0deg, #ffffff 0%, #faf5e6 62%);

$background-light-blue: #f5fcfc;

$form-gray: #979797;
$form-error: #eb5757;
$form-success: #27ae60;
$white: #ffffff;

$entry-point-green: #15983b;
$entry-point-green-hover: #11792e;
$entry-point-blue: #0a4fab;
$entry-point-blue-hover: #083d82;
$entry-point-purple: #903d9c;
$entry-point-purple-hover: #6f2f79;
$entry-point-red: #ef3531;
$entry-point-red-hover: #da1410;

$border-gray: #E9E9E9;
$text-gray: #828282;
$button-gray: #666666;
$checkbox-gray: #9C9C9C;

$material-set-blue: #2d69eb;
$material-set-orange: #e99200;
$material-set-purple: #bb5ca3;

$callout-crimson: #d63156;
$callout-purple: #b95096;
