$block-spacing: 100px;

$block-text-width-max: 600px;
$block-text-width-max-xl: 800px;

$break-xs: 375px;
$break-sm: 400px;
$break-lg: 768px;
$break-xl: 1060px;

$card-height: 400px;
$card-width: 250px;

$card-height-lg: 530px;
$card-width-lg: 333px;

$navHeight: 60px;
$bgHeaderHeight: 210px;

$bgHeaderHeight-lg: 400px;
$navHeight-lg: 95px;
